









































































import Vue from "vue";
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
import VueFlip from "vue-flip";
import { vueWindowSizeMixin } from "vue-window-size/option-api";
import platform from 'platform-detect';

import { XL_THRESHOLD } from "@/utils/constants";
import { getAddToWalletUrl } from "@/utils/backend";

declare module "vue/types/vue" {
  interface Vue {
    updateCardWidth: () => void;
  }
}

export default Vue.extend({
  name: "EMembershipCard",
  data(): { cardWidth: number; flipped: boolean; androidUrl: string; iosUrl: string; isMobile: boolean; isAndroid: boolean; isIos: boolean; } {
    return {
      cardWidth: 0,
      flipped: false,
      androidUrl: '',
      iosUrl: '',
      isMobile: false,
      isAndroid: false,
      isIos: false,
    };
  },
  created() {
    window.addEventListener("resize", this.updateCardWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateCardWidth);
  },
  computed: {
    ...mapGetters(["user"]),
    cardBackNameFontSize(): string {
      return `${this.cardWidth * 0.04}px`;
    },
    cardBackIdFontSize(): string {
      return `${this.cardWidth * 0.054}px`;
    },
    cardBackClassFontSize(): string {
      return `${this.cardWidth * 0.047}px`;
    },
    cardFrontNameFontSize(): string {
      return `${this.cardWidth * 0.059}px`;
    },
    cardFrontIdFontSize(): string {
      return `${this.cardWidth * 0.047}px`;
    },
    qrSize(): number {
      return this.cardWidth * 0.227;
    },
    mobileBackgroundTop(): string {
      return `${this.$windowWidth * 0.278}px`;
    },
    top(): string {
      if (this.$windowWidth <= XL_THRESHOLD) return "15vw";

      return "264px";
    },
  },
  components: {
    QrcodeVue,
    "vue-flip": VueFlip,
  },
  mixins: [vueWindowSizeMixin()],
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateCardWidth() {
      this.cardWidth = (
        (this.$refs.card as Vue).$el as HTMLElement
      ).clientWidth;
    },
    playVideo() {
      (this.$refs.videoRef as any).play();
    },
    async fillInAddToWalletUrl() {
      console.log(platform);

      if (platform.android || platform.ios) {
        this.isMobile = true;
        if (platform.android) {
          this.isAndroid = true;
        } else {
          this.isIos = true;
        }
      }

      const result = await getAddToWalletUrl();
      this.androidUrl = result.android_url;
    },
  },
  mounted() {
    this.updateCardWidth();
    this.fillInAddToWalletUrl();
  },
  watch: {
    flipped(newVal, oldVal) {
      if (newVal != oldVal && !newVal) {
        this.playVideo();
      }
    },
  },
});
